import React, { useState, useEffect } from "react";
import FAQMenu from "./FAQMenu";

function FAQQuestionFilterSection() {
  const [showAnswer, setShowAnswer] = useState({});
  const [activeSpan, setActiveSpan] = useState(1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    filterQuestion("tent-camp");
  }, []);
  const toggleAnswer = (id) => {
    setShowAnswer((prevShowAnswer) => ({
      //   ...prevShowAnswer,
      [id]: !prevShowAnswer[id],
    }));
  };




  function handleContainerClick(spanNumber) {
    setActiveSpan(spanNumber);
  };


  function filterQuestion(category) {
    const filteredQuestion = FAQMenu.filter((curElem) => {
      return curElem.category === category;
    });
    setItems(filteredQuestion);

  }



  return (
    <div className="faq-question-filter-section">
      <div className="faq-control-container">
        <div className="faq-control">
          <div
            className="tent-camping camp-type"
            onClick={() => {
              handleContainerClick(1);
              filterQuestion("tent-camp");
            }}
          >
            <div className={activeSpan === 1 ? "logo2" : "logo"}>
              <img src="images/facility3.png" alt="img" />
            </div>
            <p className="title">Tent Camping </p>
          </div>
          <div
            className="family-camping camp-type"
            onClick={() => {
              handleContainerClick(2);
              filterQuestion("family-camp");
            }}
          >
            <div className={activeSpan === 2 ? "logo2" : "logo"}>
              <img src="images/family-camp.png" alt="img" />
            </div>
            <p className="title">Family Camping</p>
          </div>
          <div
            className="wildcamping camp-type"
            onClick={() => {
              handleContainerClick(3);
              filterQuestion("wild-camp");
            }}
          >
            <div className={activeSpan === 3 ? "logo2" : "logo"}>
              <img src="images/camp4.png" alt="img" />
            </div>
            <p className="title">Wild Camping  </p>
          </div>
        </div>
        <div className="custom-line">
          <span className={activeSpan === 1 ? "span" : "span2"}></span>
          <span className={activeSpan === 2 ? "span" : "span2"}></span>
          <span className={activeSpan === 3 ? "span" : "span2"}></span>
        </div>
      </div>
      {items.map((elem) => (
        <div className="card" key={elem.id}>
          <div
            className="question-container"
            onClick={() => toggleAnswer(elem.id)}
          >
            <p className="question">{elem.question}</p>
            <img src="images/qarrow.png" alt="img" />
          </div>
          <hr className={showAnswer[elem.id] ? "hr" : "hr2"} />
          <p className={showAnswer[elem.id] ? "answer" : "answer2"}>
            {elem.answer}
          </p>
        </div>
      ))}
    </div>
  );
}

export default FAQQuestionFilterSection;