const WhoWeAreObjectiveMenu = [
    {
        id: 1,
        image: "images/mission.png",
        title: "Our Mission",
        description: "Tincidunt egestas morbi urna urnabelit tincidunt pellentesque urnaleorem cursus lacinia. Amet viverrascelerisque faucibus"
    },
    {
        id: 1,
        image: "images/vision.png",
        title: "Our Vision",
        description: "Tincidunt egestas morbi urna urnabelit tincidunt pellentesque urnaleorem cursus lacinia. Amet viverrascelerisque faucibus"
    },
    {
        id: 1,
        image: "images/goal.png",
        title: "Company Goals",
        description: "Tincidunt egestas morbi urna urnabelit tincidunt pellentesque urnaleorem cursus lacinia. Amet viverrascelerisque faucibus"
    }
]

export default WhoWeAreObjectiveMenu;