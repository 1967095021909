const FacilitiesMenu = [
    {
        id: 1,
        image: "images/facility1.png",
        title: "Private Cottages",
        description: "Quisque vulputate amtacipe aliuam diam sitis etia",
        imageBackgroundColor: "#f7921e",
        cardBackgroundColor: "#fff1ea"
    },
    {
        id: 2,
        image: "images/facility2.png",
        title: "Tents On Rent",
        description: "Quisque vulputate amtacipe aliuam diam sitis etia",
        imageBackgroundColor: "#63ac45",
        cardBackgroundColor: "#f0f7ed"
    },
    {
        id: 3,
        image: "images/facility3.png",
        title: "Summer Camp",
        description: "Quisque vulputate amtacipe aliuam diam sitis etia",
        imageBackgroundColor: "#f7921e",
        cardBackgroundColor: "#fff1ea"
    },
    {
        id: 4,
        image: "images/facility4.png",
        title: "Wood Fire",
        description: "Quisque vulputate amtacipe aliuam diam sitis etia",
        imageBackgroundColor: "#63ac45",
        cardBackgroundColor: "#f0f7ed"
    }
]

export default FacilitiesMenu;