import React from "react";
import AccommodationMenu from "./AccommodationMenu";


function AvailableAccommodations() {
    return (
        <div className="available-accommodations">
            <div className="available-accommodation-header">
                <p className="result">8 Accommodations found</p>
            </div>
            <hr className="header-hr" />
            <div className="accommodation-types">
                {
                    AccommodationMenu.map((item) => {
                        return (
                            <div className="cards">
                                <img className="card-img" src={item.image} alt="img" />
                                <div className="title">
                                    <hr className="title-hr" />
                                    <h2>{item.title}</h2>
                                </div>
                                <p>{item.description}</p>
                                <hr className="card-hr" />
                                <div className="card-foot">
                                    <div className="foot-icons">
                                        <img className="card-foot-img" src="images/acco-card1.png" alt="img" />
                                        <img className="card-foot-img" src="images/acco-card2.png" alt="img" />
                                        <img className="card-foot-img" src="images/acco-card3.png" alt="img" />
                                        <img className="card-foot-img" src="images/acco-card4.png" alt="img" />
                                    </div>
                                    <div className="foot-arrow">
                                        <img src="images/card-foot-arrow.png" alt="img" />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default AvailableAccommodations;