import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


function Header() {
    const [scrolled, setScrolled] = useState(false);

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);


        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);
    return (
        <div className={`header ${scrolled ? 'scrolled' : ''}`} >
            <Link to={"/"}>
                <img className="header_logo" src="images/logo-white.png" alt="logo" />
            </Link>
            <div className= {showMenu ? "header_menu2" : "header_menu"}>
                <ul>
                    <li><Link to={"/"} >Home</Link></li>
                    <li>
                        <div class="dropdown">
                            <button>Accomodation <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path fill="#ffffff" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg></button>
                            <div class="dropdown-content">
                                <Link to={"/accommodation_standards"}>Accommodation Standards</Link>
                                <Link to={"/"}>Cart</Link>
                                <Link to={"/"}>Checkout</Link>
                                <Link to={"/"}>My account</Link>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown">
                            <button>Pages <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path fill="#ffffff" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg></button>
                            <div class="dropdown-content">
                                <Link to={"/about_us"}>About Us</Link>
                                <Link to={"/faq"}>FAQ</Link>
                                <Link to={"/"}>Gallery</Link>
                            </div>
                        </div>
                    </li>
                    <li>
                        <Link to={"/blog"}>Blog</Link>
                    </li>
                    <li>Contact</li>
                </ul>
            </div>
            <div className="hamburger-menu">
                    <a href="/" onClick={() => setShowMenu(!showMenu)}>
                        <img src="images/hamburger-menu.png" alt="img" />
                    </a>
                </div>
            <button className="nav_book_btn">BOOK NOW</button>
        </div>
    );
}

export default Header;