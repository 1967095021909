import React from "react";
import FacilitiesMenu from "./FacilitiesMenu";
import {Link} from "react-router-dom";


function OurFacilities() {
    return (
        <div className="our-facilities">
            {
                FacilitiesMenu.map((items) => {
                    return (
                        <div className="cards" style={{ backgroundColor: items.cardBackgroundColor }}>
                            <div className="card-img" style={{ backgroundColor: items.imageBackgroundColor, borderColor: items.imageBackgroundColor }}>
                                <img src={items.image} alt="img" />
                            </div>
                            <p className="title">{items.title}</p>
                            <p className="description">{items.description}</p>
                            <hr />
                            {/* <p className="learn-more">LEARN MORE</p> */}
                            <Link to={"/"} className="learn-more">LEARN MORE</Link>
                        </div>
                    );
                })
            }
        </div>
    );
}


export default OurFacilities;