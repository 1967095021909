import React from "react";
import HomeBanner from "../components/HomeBanner";
import Header from "../components/Header"
import Camping from "../components/Camping";
import { useEffect } from "react";
import Footer from "../components/Footer";


function Home() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="main">
            <Header />
            <HomeBanner />
            <Camping />
            <Footer />
        </div>
    );
}

export default Home;