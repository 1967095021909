import React from "react";
import BlogMenu from "./BlogMenu";


function BlogCards() {
    return (
        <div className="blog-cards">
            {
                BlogMenu.map((item) => {
                    return (
                        <div className="card">
                            <img src={item.image} alt="img" />
                            <div className="publish-details">
                                <div className="date publish-column">
                                    <img src="images/calender.png" alt="img"/>
                                    <p>{item.date}</p>
                                </div>
                                <div className="author publish-column">
                                    <img src="images/person.png" alt="img"/>
                                    <p className="name">{item.name}</p>
                                </div>
                                <div className="comment publish-column">
                                    <img src="images/comment.png" alt="img"/>
                                    <p>0 Comments</p>
                                </div>
                            </div>
                            <p className="title">{item.title}</p>
                            <p className="description">{item.description}</p>
                            <p className="read-more">READ MORE {">>"}</p>
                        </div>
                    );
                })

            }
        </div>
    );
}


export default BlogCards;