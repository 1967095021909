import React from "react";


function DiscoverMore() {
    return (
        <div className="discover-more-container">
            <div className="discover-more">
                <div className="discover-heading">
                    <p className="heading">
                        04 <span> <hr /> </span> Discover More
                    </p>
                </div>
                <div className="discover-more-details">
                    <div className="discover-content">
                        <p className="title">
                            <span>Better Travel to Change </span>
                            your Better Emotions
                        </p>
                        <p className="description">Sit amet consectetur adipiscing elit. Venenatis amet ut et, elementum. In sem nec ipsum, tincidunt risus maecenas. Ut volutpat quis a mollis neque nunc aliquet et. Condimentum habitant arcu malesuada odio luct ultrices. A felis, ipsum sit tempor praesent eu vitae tellus porttitor</p>
                    </div>
                    <div className="discover-img">
                        <img src="images/discover.jpg" alt="img" />
                    </div>

                </div>
            </div>
            <div className="discover-foot">
                <p className="discover-foot-heading"> <span>Enjoy The Better Adventure
                    Life</span> Tent Camping</p>
                <p className="camp">Camping</p>
                <button className="discover-btn">DISCOVER MORE</button>
            </div>
        </div>
    );
}


export default DiscoverMore;