import React from "react";
import TestimonialSlider from "./TestimonialSlider";


function Testimonial() {
    return (
        <div className="testimonial">
            <div className="testimonial-content">
                <div className="testimonial-slider">
                    <TestimonialSlider />
                </div>
                <div className="testimonial-heading">
                    <p className="heading">
                        05 <span> <hr /> </span> Testimonial
                    </p>
                    <p className="title"> <span>What Our Client Say </span>
                        About Us</p>
                </div>
            </div>
        </div>
    );
}


export default Testimonial;