const CampingFacilityMenu = [
    {
        id: 1,
        image: "images/green-private.png",
        title: "Private Cottages",
        description: "Sit amet consectetur adipiscing elitcelerisque aenectus bibendum facilisi tempus",
    },
    {
        id: 2,
        image: "images/green-tent.png",
        title: "Tents on Rent",
        description: "Sit amet consectetur adipiscing elitcelerisque aenectus bibendum facilisi tempus",
    },
    {
        id: 3,
        image: "images/green-summer.png",
        title: "Summer Camp",
        description: "Sit amet consectetur adipiscing elitcelerisque aenectus bibendum facilisi tempus",
    },
    {
        id: 4,
        image: "images/green-wood.png",
        title: "Wood Fire",
        description: "Sit amet consectetur adipiscing elitcelerisque aenectus bibendum facilisi tempus",
    },
    {
        id: 1,
        image: "images/green-electrical.png",
        title: "Electrical Cabinets",
        description: "Sit amet consectetur adipiscing elitcelerisque aenectus bibendum facilisi tempus",
    },
    {
        id: 1,
        image: "images/green-Washing.png",
        title: "Washing Machine",
        description: "Sit amet consectetur adipiscing elitcelerisque aenectus bibendum facilisi tempus",
    }
]


export default CampingFacilityMenu;