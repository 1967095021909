const AccommodationMenu = [
    {
        id: 1,
        image: "images/acco1.jpg",
        title: "Classic Tent",
        description: "We denounce with righteous indignation and dislike mens beguiled...",
    },
    {
        id: 1,
        image: "images/acco2.jpg",
        title: "Camper",
        description: "We denounce with righteous indignation and dislike mens beguiled...",
    },
    {
        id: 1,
        image: "images/acco3.jpg",
        title: "Tent Camping",
        description: "We denounce with righteous indignation and dislike mens beguiled...",
    },
    {
        id: 1,
        image: "images/acco4.jpg",
        title: "Tree House Tent",
        description: "We denounce with righteous indignation and dislike mens beguiled...",
    },
    {
        id: 1,
        image: "images/acco5.jpg",
        title: "Motorhome",
        description: "We denounce with righteous indignation and dislike mens beguiled...",
    },
    {
        id: 1,
        image: "images/acco6.jpg",
        title: "Wild Camper",
        description: "We denounce with righteous indignation and dislike mens beguiled...",
    },
    {
        id: 1,
        image: "images/acco7.jpg",
        title: "Safari Tent Camping",
        description: "We denounce with righteous indignation and dislike mens beguiled...",
    },
    {
        id: 1,
        image: "images/acco8.jpg",
        title: "Couple Tent Camping",
        description: "We denounce with righteous indignation and dislike mens beguiled...",
    }
]

export default AccommodationMenu;