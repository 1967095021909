const ProfessionalMenu = [
    {
        id: 1,
        image: "images/prof1.png",
        name: "Michael F. Jimenez",
        position: "CEO & Founder"
    },
    {
        id: 2,
        image: "images/prof2.png",
        name: "Kenneth I. Phillips",
        position: "Senior Manager"
    },
    {
        id: 3,
        image: "images/prof3.png",
        name: "Alfonso J. Marrero",
        position: "Junior Consultant"
    },
    {
        id: 4,
        image: "images/prof4.png",
        name: "Jackie Y. Hickman",
        position: "Business Consultant"
    }
]


export default ProfessionalMenu;