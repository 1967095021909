import React, { useEffect } from "react";
import Header from "../components/Header";
import SecondaryBanner from "../components/SecondaryBanner";
import InnerAboutUs from "../components/InnerAboutUs";
import WhoWeAre from "../components/WhoWeAre";
import TeamMember from "../components/TeamMember";
import DiscoverMore from "../components/DiscoverMore";
import Testimonial from "../components/Testimonial";
import Footer from "../components/Footer";


function AboutUs() {

    useEffect(() =>{
        window.scrollTo(0,0);
    }, []);

    return (
        <div>
            <Header />
            <SecondaryBanner
                page="About Us"
            />
            <InnerAboutUs />
            <WhoWeAre />
            <TeamMember />
            <DiscoverMore />
            <Testimonial />
            <Footer />
        </div>
    );
}

export default AboutUs;