import React from "react";
import BannerSlider from "./BannerSlider";

function HomeBanner(){

    

    return(
        <div className="banner_slider">
        <BannerSlider />
        </div>
    );
}


export default HomeBanner;