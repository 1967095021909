import React from "react";
import WhoWeAreObjectiveMenu from "./WhoWeAreObjectiveMenu";
import {Link} from "react-router-dom";


function WhoWeAre() {
    return (
        <div className="who-we-are">
            <div className="who-banner">
                <div className="detailed-section">
                    <p className="who-heading">02 <span> <hr /> </span> Who We Are</p>
                    <p className="title">Company Mission & Vision</p>

                    <div className="objective">

                        {
                            WhoWeAreObjectiveMenu.map((elem) => {
                                return (
                                    <Link to={"/"} style={{textDecoration: "none", color: "black"}}>
                                        <div className="cards">
                                            <div className="card-img">
                                                <img src={elem.image} alt="img" />
                                            </div>
                                            <p className="card-title">{elem.title}</p>
                                            <p className="card-description">{elem.description}</p>
                                            <hr />
                                        </div>
                                    </Link>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhoWeAre;