const FAQMenu = [
    {
        id: 1,
        question: "Design For Mobile Interfaces, A New Smashing Book By Steven Hoober",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "tent-camp"
    },
    {
        id: 2,
        question: "Terminal And Command-Line (CLI) Tools For Modern Web Development",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "tent-camp"
    },
    {
        id: 3,
        question: "Useful React Hooks That You Can Use In Your Projects",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "tent-camp"
    },
    {
        id: 4,
        question: "Automatically Transforming And Optimizing Images And Videos",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "tent-camp"
    },
    {
        id: 5,
        question: "The Value Of Concept Testing As Part Of Product Design",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "tent-camp"
    },
    {
        id: 6,
        question: "50 Resources And Tools To Turbocharge Your Copywriting Skills",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "tent-camp"
    },
    {
        id: 7,
        question: "Useful React Hooks That You Can Use In Your Projects",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "family-camp"
    },
    {
        id: 8,
        question: "Automatically Transforming And Optimizing Images And Videos",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "family-camp"
    },
    {
        id: 9,
        question: "Design For Mobile Interfaces, A New Smashing Book By Steven Hoober",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "family-camp"
    },
    {
        id: 10,
        question: "Terminal And Command-Line (CLI) Tools For Modern Web Development",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "family-camp"
    },
    {
        id: 11,
        question: "The Value Of Concept Testing As Part Of Product Design",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "family-camp"
    },
    {
        id: 12,
        question: "50 Resources And Tools To Turbocharge Your Copywriting Skills",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "family-camp"
    },
    {
        id: 13,
        question: "50 Resources And Tools To Turbocharge Your Copywriting Skills",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "wild-camp"
    },
    {
        id: 14,
        question: "The Value Of Concept Testing As Part Of Product Design",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "wild-camp"
    },
    {
        id: 15,
        question: "Useful React Hooks That You Can Use In Your Projects",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "wild-camp"
    },
    {
        id: 16,
        question: "Terminal And Command-Line (CLI) Tools For Modern Web Development",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "wild-camp"
    },
    {
        id: 17,
        question: "Design For Mobile Interfaces, A New Smashing Book By Steven Hoober",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "wild-camp"
    },
    {
        id: 18,
        question: "Automatically Transforming And Optimizing Images And Videos",
        answer: "Sit amet, consectetur adipiscing elit. Id massa hendrerit dis tellus ullamcorper sit convallis et dui. Scelerisque tortor nulla pellentesque augue. Vestibulum morbi eget venenatis elementum, pulvinar vel mauris pellentesque. Nulla elit sodales lacus risus tincidunt quis nec neque, sem. Donec varius montes, mi id sagittis tincidunt eu. Vulputate libero pellentesque morbi montes, morbi a tristique suspendisse nec. Lectus ac faucibus et a eu. Donec proin cursus molestie ut convallie",
        category: "wild-camp"
    }
    
]


export default FAQMenu;