import React from "react";


function Footer() {
    return (
        <div className="footer">
            <div className="news-letter">
                <p className="title">
                    <span>Subscribe Our Newsletter</span>
                    Get Every Update
                </p>
                <div className="subscription-form">
                    <form>
                        <input placeholder="Email Address" />
                        <button>SUBSCRIBE</button>
                    </form>
                    <label for="accept-checkbox">
                        <input type="checkbox" id="accept" name="accept" />
                        I agree to all terms and policies of the company
                    </label>
                </div>
            </div>
            <hr />
            <div className="footer-columns">
                <div className="company-column">
                    <img className="logo" src="images/logo-white.png" alt="img" />
                    <p>Sit amet consectetur adipiscinelit Sem et aliquam enimdeassa ornare vulputate neque feugiat secursun blandit volutpat hendr mauris</p>
                    <div className="footer-company-social-media">
                        <div className="image-container"> <img src="images/facebook.png" alt="img" /></div>
                        <div className="image-container"><img src="images/twitter.png" alt="img" /></div>
                        <div className="image-container"> <img src="images/instagram.png" alt="img" /></div>
                        <div className="image-container"> <img src="images/linkedin.png" alt="img" /></div>
                    </div>
                </div>
                <div className="links-column">
                    <p className="heading">Links</p>
                    <nav className="footer-nav">
                        <ul>
                            <li>Home</li>
                            <li>Pricing</li>
                            <li>About us</li>
                            <li>Careers</li>
                            <li>Features</li>
                            <li>Blogs</li>
                        </ul>
                    </nav>
                </div>
                <div className="services-column">
                    <p className="heading">Services</p>
                    <nav className="footer-nav">
                        <ul>
                            <li>Caravan Soler Tent</li>
                            <li>Family Tent Camping</li>
                            <li>Classic Tent Camping</li>
                            <li>Wild Tent Camping</li>
                            <li>Bell Glamp One</li>
                            <li>Small Cabin Wood</li>
                        </ul>
                    </nav>
                </div>
                <div className="support-column">
                    <p className="heading">Support</p>
                    <nav className="footer-nav">
                        <ul>
                            <li>Meet Our Team</li>
                            <li>Item Support</li>
                            <li>Contact Foram</li>
                            <li>Report Abuse</li>
                            <li>Live</li>
                        </ul>
                    </nav>
                </div>
                <div className="location-column">
                    <p className="heading">Location</p>
                    <div className="column-details">
                        <img src="images/location.png" alt="img" />
                        <p>55 Main Street, 2nd-Block melbourne, Australia</p>
                    </div>
                    <p className="heading">Contact</p>
                    <div className="column-details">
                        <img src="images/email.png" alt="img" />
                        <p>55 Main Street, 2nd-Block melbourne, Australia</p>
                    </div>
                    <div className="column-details">
                        <img src="images/call.png" alt="img" />
                        <p>55 Main Street, 2nd-Block melbourne, Australia</p>
                    </div>
                </div>
            </div>
            <hr />
            <div className="footer-foot">
                <p>Copy@2024 Lake view Resort, All Right Reserved</p>
                <nav className="footer-foot-nav">
                    <ul>
                        <li>Setting & Privacy</li>
                        <li>Faqs</li>
                        <li>Support</li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}


export default Footer;