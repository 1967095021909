import React, {useEffect} from "react";
import SecondaryBanner from "../components/SecondaryBanner";
import Header from "../components/Header";
import FAQQuestionFilterSection from "../components/FAQQuestionFilterSection";
import Footer from "../components/Footer";


function FAQ() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Header />
            <SecondaryBanner
                page="FAQ"
            />
            <FAQQuestionFilterSection />
            <Footer />
        </div>
    );
}

export default FAQ;