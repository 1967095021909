import React from "react";
import SecondaryBanner from "../components/SecondaryBanner";
import Header from "../components/Header";
import AccommodationCheckForm from "../components/AccommodationCheckForm";
import AvailableAccommodations from "../components/AvailableAccommodations";
import { useEffect } from "react";
import Footer from "../components/Footer";


function AccommodationStandards() {

    useEffect(() =>{
        window.scrollTo(0,0);
    }, []);
    return (
        <div>
            <Header />
            <SecondaryBanner 
                page = "Accommodation Standard"
            />
            <AccommodationCheckForm />
            <AvailableAccommodations />
            <Footer />
        </div>
    );
}

export default AccommodationStandards;