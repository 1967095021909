import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

function BannerSlider() {
  return (
    <Carousel data-bs-theme="dark" className='banner_carousel' pause="false">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="images/img1.jpg"
          alt="First slide"
          style={{ filter: "brightness(0.7)" }}
        />
        <Carousel.Caption className='banner_text'>
          <p className='banner_text_1'>
            Travel & <br />
            Adventure
          </p>
          <div className='banner_button'>
            <button className='discover'>DISCOVER MORE</button>
            <button className='how'>HOW IT WORKS</button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="images/img2.jpg"
          alt="Second slide"
          style={{ filter: "brightness(0.7)" }}
        />
        <Carousel.Caption className='banner_text'>
          <p className='banner_text_1'>
            Travel & <br />
            Camping
          </p>
          <div className='banner_button'>
            <button className='discover'>DISCOVER MORE</button>
            <button className='how'>HOW IT WORKS</button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="images/img4.jpg"
          alt="Third slide"
          style={{ filter: "brightness(0.7)" }}
        />
        <Carousel.Caption className='banner_text'>
          <p className='banner_text_1'>
            Camping & <br />
            Adventure
          </p>
          <div className='banner_button'>
            <button className='discover'>DISCOVER MORE</button>
            <button className='how'>HOW IT WORKS</button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default BannerSlider;