import React from "react";
import ProfessionalMenu from "./ProfessionalMenu";

function TeamMember() {
    return (
        <div className="team-member">
            <p className="heading">
                03 <span> <hr /> </span> Team Member
            </p>
            <p className="title"><span>Meet Our Professional</span>
                Team Member</p>
            <div className="team-details">

                {
                    ProfessionalMenu.map((item) => {
                        return (
                            <div className="cards">
                                <img src={item.image} alt="img" />
                                <div className="social-media">
                                    <img src="images/facebook.png" alt="img" />
                                    <img src="images/twitter.png" alt="img" />
                                    <img src="images/instagram.png" alt="img" />
                                    <img src="images/linkedin.png" alt="img" />
                                </div>
                                <p className="name">{item.name}</p>
                                <p className="position">{item.position}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default TeamMember;