import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';

const TestimonialSlider = () => {
    const [index, setIndex] = useState(0);

    const testimonials = [
        {
            id: 1,
            image: "images/prof1.png",
            name: 'John Doe',
            quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
        {
            id: 2,
            image: "images/prof2.png",
            name: 'Jane Doe',
            quote: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
        {
            id: 3,
            image: "images/prof3.png",
            name: 'Alice Smith',
            quote: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    ];

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 5000); // Adjust the interval (in milliseconds) according to your preference

        return () => clearInterval(interval);
    }, [testimonials.length]);

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {testimonials.map((testimonial) => (
                <Carousel.Item key={testimonial.id}>
                    <div className="slides">
                        <div className='id-details'>
                            <img src={testimonial.image} alt='img' />
                            <div className='name-position'>
                                <p className="name">{testimonial.name}</p>
                                <p className='position'>CEO & Founder</p>
                            </div>
                        </div>
                        <p className="quote">{testimonial.quote}</p>
                        <div className='rating'>
                            <img src='images/star.png' alt='img' />
                            <img src='images/star.png' alt='img' />
                            <img src='images/star.png' alt='img' />
                            <img src='images/star.png' alt='img' />
                            <img src='images/star.png' alt='img' />
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default TestimonialSlider;