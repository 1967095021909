import React from "react";
import { Link } from "react-router-dom";


function SecondaryBanner(props) {
    return (
        <div className="secondary_banner">
            <div className="banner_container">
                <div className="secondary-banner-text">
                    <p className="banner_text">{props.page}</p>
                    <div className="shortcut_home">
                        <Link to={"/"} >Home</Link>
                        <p>{">"} {props.page}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecondaryBanner;