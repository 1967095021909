import React from "react";
import Header from "../components/Header";
import SecondaryBanner from "../components/SecondaryBanner";
import BlogCards from "../components/BlogCards";
import Footer from "../components/Footer";


function Blog(){
    return(
        <div>
        <Header />
        <SecondaryBanner 
            page= "Blog"
        />
        <BlogCards />
        <Footer />
        </div>
    );
}


export default Blog;