import React from "react";


function AboutUsDetails() {
    return (
        <div className="about-us-details">
            <p className="heading">
                <span>We’re Most Trusted
                    Travel
                </span> Partner Around
                The World
            </p>
            <p className="description">
                Sorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate volutpat bibendum imperdiet sit fermentum non. Vel sed neque, fringilla facilisi neque fusce faucibus erat. Tincidunt egestas morbi urna urna, at mi. Velit tincidunt pellentesque urna, leo lorem cursus lacinia. Amet viverra habitasse euismod scelerisque faucibus porttitor miey
            </p>
        </div>
    );
}

export default AboutUsDetails;