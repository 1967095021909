const BlogMenu = [
    {
        image: "http://localhost:3000/images/blog1.jpg",
        name: "Alex",
        date: "March 27, 2023",
        title: "Web Quality Assurance Requirements To Web Dev Risk Manage",
        description: "Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many variations of passages of Lorem Ipsum available, but the majority have alteration in some injected or words which don’t look even slightly believable."
    },
    {
        image: "images/blog2.jpg",
        name: "Alex",
        date: "March 27, 2023",
        title: "Meet Touch Design Mobile Interfaces Smashing Book Steven",
        description: "Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many variations of passages of Lorem Ipsum available, but the majority have alteration in some injected or words which don’t look even slightly believable."
    },
    {
        image: "images/blog3.jpg",
        name: "Alex",
        date: "March 27, 2023",
        title: "50 Resources And Tools To Turbocharge Copywriting Skills",
        description: "Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many variations of passages of Lorem Ipsum available, but the majority have alteration in some injected or words which don’t look even slightly believable."
    },
    {
        image: "images/blog4.jpg",
        name: "Alex",
        date: "March 27, 2023",
        title: "Building A Static-First MadLib Generator With Portable Text Netlify",
        description: "Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many variations of passages of Lorem Ipsum available, but the majority have alteration in some injected or words which don’t look even slightly believable."
    },
    {
        image: "images/blog5.jpg",
        name: "Alex",
        date: "March 27, 2023",
        title: "How To Implement Search Functionality In Your Nuxt App",
        description: "Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many variations of passages of Lorem Ipsum available, but the majority have alteration in some injected or words which don’t look even slightly believable."
    },
    {
        image: "images/blog6.jpg",
        name: "Alex",
        date: "March 27, 2023",
        title: "Monetize Open-Source Software With Gatsby Functions",
        description: "Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many variations of passages of Lorem Ipsum available, but the majority have alteration in some injected or words which don’t look even slightly believable."
    }
]


export default BlogMenu;