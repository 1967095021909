import React from "react";
import AboutUsDetails from "./AboutUsDetails";
import OurFacilities from "./OurFacilities";

function InnerAboutUs(){
    return(
        <div className="inner-about-us">
        <p className="about-us-heading">01 <span><hr /></span> About Us</p>
        <AboutUsDetails />
        <OurFacilities />
        </div>
    );
}

export default InnerAboutUs;