import React from "react";
import Home from "./routes/Home";
import { Routes, Route } from "react-router-dom";
import AccommodationStandards from "./routes/AccommodationStandards";
import AboutUs from "./routes/AboutUs";
import FAQ from "./routes/FAQ";
import Blog from "./routes/Blog";


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/accommodation_standards" element={<AccommodationStandards />}/>
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/blog" element={<Blog />}/>
      </Routes>
    </div>
  );
}

export default App;
