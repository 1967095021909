import React from "react";
import AccommodationMenu from "./AccommodationMenu";
import CampingFacilityMenu from "./CampingFacilityMenu.js";
import { Link } from "react-router-dom";

function Camping() {

    const CampingMenu = AccommodationMenu.slice(0, 4);
    return (
        <div>
            <div className="camping_container">
                <div className="camping_content">
                    <div className="hp">
                        <h2 className="Camping_heading">Have A Fun With Our Amazing</h2>
                        <p className="camping_paragraph">Camping Ground</p>
                    </div>
                    <div className="camp_type">

                        {
                            CampingMenu.map((elem) => {
                                return (
                                    <div className="cards">
                                        <div className="arrow">
                                            <img src="images/45arrow.png" alt="img" />
                                        </div>
                                        <img className="card-img" src={elem.image} alt="img" />
                                        <div className="title">
                                            <hr className="title-hr" />
                                            <p>{elem.title}</p>
                                        </div>
                                        <p className="description">{elem.description}</p>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {/* <div className="camping-facility-type">
                        {
                            CampingFacilityMenu.map((elem) => {
                                return (
                                    <div className="cards">
                                        <div className="logo-title">
                                            <img src={elem.image} alt="img" />
                                            <p className="title">{elem.title}</p>
                                        </div>
                                        <div class="custom-line">
                                            <span></span>
                                        </div>
                                        <p className="description">{elem.description}</p>
                                        <p className="read-more">READ MORE</p>
                                    </div>
                                );
                            })
                        }
                    </div> */}
                </div>
            </div>
            <div className="travel-with-us">
                <img className="bg-img" src="images/home-video-section.jpg" alt="img" />
                <div className="content">
                    <div className="title-button">
                        <p className="title">
                            Ready to Get Started your <br />
                            Travel Camping Us
                        </p>
                        <Link to={"https://youtu.be/XHOmBV4js_E?si=ed3YKC7nNZ5SlNb-"}>
                            <button className="watch-video">WATCH VIDEO</button>
                        </Link>
                    </div>
                    <Link to={"https://youtu.be/XHOmBV4js_E?si=ed3YKC7nNZ5SlNb-"}>
                        <div className="separate-watch-button">
                            <img className="play" src="images/play.png" alt="img" />
                        </div>
                    </Link>
                </div>
            </div>
            <div className="camping_container">
                <div className="camping_content">
                    <div className="camping-facility-type">
                        {
                            CampingFacilityMenu.map((elem) => {
                                return (
                                    <div className="cards">
                                        <div className="logo-title">
                                            <img src={elem.image} alt="img" />
                                            <p className="title">{elem.title}</p>
                                        </div>
                                        <div class="custom-line">
                                            <span></span>
                                        </div>
                                        <p className="description">{elem.description}</p>
                                        <p className="read-more">READ MORE</p>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Camping;