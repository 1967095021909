import React from "react";


function AccommodationCheckForm() {
    return (
        <div className="accommodation_check_form">
            <form>
                {/* Check-in Date */}
                <div className="field check-in">
                    <label>Check in</label>
                    <input
                        type="date"
                        id="checkinDate"
                        name="checkinDate"
                        required
                    />
                </div>
                <br />

                {/* Checkout Date */}
                <div className="field check-out">
                    <label>Check out</label>
                    <input
                        type="date"
                        id="checkoutDate"
                        name="checkoutDate"
                        required
                    />
                </div>
                <br />

                {/* Number of Guests */}
                <div className="field guest">
                    <label>Guests</label>
                    <input
                        type="number"
                        id="numGuests"
                        name="numGuests"
                        min="1"
                        max="10"
                        required
                    />
                </div>
                <br />

                {/* Accommodations Dropdown */}
                <div className="field accommodation" style={{display: "flex", flexDirection: "column"}}>
                    <label>Accommodations</label>
                    <select
                        id="accommodations"
                        name="accommodations"
                    >
                        <option value="all">All Accommodations</option>
                        <option value="tent">Tent</option>
                        <option value="camp">Camp</option>
                        <option value="trailer">Trailer</option>
                    </select>
                </div>
                <br />

                {/* Submit Button */}
                <div className="field submit">
                <label style={{visibility: "hidden"}}>Submit:</label>
                <button type="submit">CHECK AVAILABILITY</button>
                </div>
            </form>
        </div>
    );
}

export default AccommodationCheckForm;